import React from "react";
import Grid from "@mui/material/Grid";
import DashboardCard from "./Card";
import PeopleIcon from "@mui/icons-material/People";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../hooks/useDispatch";
import { periods } from "../../utils/userUtils";
import { PeriodDropdown } from "../PeriodDropdown";

const Cards: React.FC = () => {
  const [selectedPeriod, setSelectedPeriod] = React.useState<string>(
    periods.allTime
  );

  const registeredUsers = useAppSelector(
    (state) => state.analytics.registeredUsers
  );
  const subscribedUsers = useAppSelector(
    (state) => state.analytics.subscribedUsers
  );
  const canceledUsers = useAppSelector(
    (state) => state.analytics.canceledUsers
  );

  // Dummy data array for the cards, replace with actual data and import the icons
  const cardData = [
    {
      icon: <PeopleIcon sx={{ fontSize: 60 }} />,
      title: "Users",
      data: registeredUsers,
      selectedPeriod: selectedPeriod,
    },
    {
      icon: <StarBorderIcon sx={{ fontSize: 60 }} />,
      title: "Subscriptions",
      data: subscribedUsers,
      selectedPeriod: selectedPeriod,
    },
    //{
    //  icon: <NewReleasesIcon sx={{ fontSize: 60 }} />,
    //  title: "New paid subscriptions",
    //  value: "20",
    //  data: registeredUsers,
    //  selectedPeriod: selectedPeriod,
    //},
    {
      icon: <CancelIcon sx={{ fontSize: 60 }} />,
      title: "Canceled Users",
      data: canceledUsers,
      selectedPeriod: selectedPeriod,
    },
  ];

  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);
    // You would also handle updating any other state or fetching data here if needed
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", my: 2 }}
        >
          Analytics Overview
        </Typography>

        <PeriodDropdown
          selectedPeriod={selectedPeriod}
          onSelectPeriod={handlePeriodChange}
        />
      </Box>
      <Grid container justifyContent="center" spacing={2}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <DashboardCard key={index} {...card} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Cards;
