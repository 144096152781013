import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

type ListItemsProps = {
  navigate: (path: string) => void;
};

export const MainListItems: React.FC<ListItemsProps> = ({ navigate }) => {

  return (
  <div>
    <ListItem button onClick={() => navigate('/dashboard')} sx={{ color: 'white' }}>
      <ListItemIcon sx={{ color: 'white' }}>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button onClick={() => navigate('/users')} sx={{ color: 'white' }}>
      <ListItemIcon sx={{ color: 'white' }}>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItem>
    {/* Add more items as needed */}
  </div>
);
  }

export const SecondaryListItems: React.FC<ListItemsProps> = ({ navigate }) => {

  return (
  <div>
    <ListItem button onClick={() => navigate('/accounts-management')} sx={{ color: 'white' }}>
      <ListItemIcon sx={{ color: 'white' }}>
        <AdminPanelSettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Accounts" />
    </ListItem>
    {/* Add more items as needed */}
  </div>
);
  }