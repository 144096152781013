import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  // Retrieve the userInfo string from localStorage
  const userInfoString = localStorage.getItem("userInfo");

  // Safely parse the userInfo string to a JSON object
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

  // Check if token exists in the parsed userInfo
  const token = userInfo ? userInfo.token : null;
  // Redirect to login page if the token is not available
  if (!token) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
