import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Chip, FormControl, IconButton } from "@mui/material";
import theme from "../theme";
import { BootstrapInput } from "../theme/components";
import EditIcon from "@mui/icons-material/Edit";
import EditUserModal from "./modals/EditUserModal";
import { handleChipColor } from "../utils/chipColorHelpers";
import { useAppSelector } from "../hooks/useDispatch";
import { RegisteredUser } from "types/apiResponses";

export default function UsersTable() {
  const [open, setOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<RegisteredUser | null>(null);
  const users = useAppSelector(state => state.analytics.registeredUsers);

  const handleEditUserModal = (user: RegisteredUser) => {
    setSelectedUser(user);
    setOpen(true);
  };
  return (
    <Box component={Paper}>
      <Box sx={{ m: 1, display: "flex", justifyContent: "end" }}>
        <FormControl sx={{ m: 1 }} variant="standard">
          <BootstrapInput
            id="demo-customized-textbox"
            placeholder="Search..."
          />
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Plan Type</TableCell>
              <TableCell align="center">Access</TableCell>
              <TableCell align="center">Registered</TableCell>
              <TableCell align="center">Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  {user.name}
                </TableCell>
                <TableCell align="center">{user.email}</TableCell>
                <TableCell align="center">{user.subscription ? user.subscription.planType : ''}</TableCell>
                <TableCell align="center">
                  <Chip
                    label={user.subscription ? user.subscription.subscriptionStatus : 'no subscription'}
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: handleChipColor(user.subscription ? user.subscription.subscriptionStatus : 'no subscription'),
                      color: "white",
                      fontSize: "0.875rem",
                      mb: 2,
                    }}
                  />
                </TableCell>
                <TableCell align="center">{new Date(user.createdAt).toLocaleDateString()}</TableCell>
                <TableCell align="center">
                  <IconButton
                    //edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => handleEditUserModal(user)}
                    sx={{
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedUser && <EditUserModal user={selectedUser} open={open} handleClose={() => setOpen(false)} />}
    </Box>
  );
}
