import { RegisteredUser, RegisteredUserCount, SubscribedUser } from "../../types/apiResponses";
import axiosInstance from "../axiosInstance";

const getRegisteredUsers = async (): Promise<RegisteredUser[]> => {
  const response = await axiosInstance.get<RegisteredUser[]>("/analytics/registered-users");
  return response.data;
};

const getCountOfRegisteredUsers = async (): Promise<RegisteredUserCount> => {
  const response = await axiosInstance.get<RegisteredUserCount>("/analytics/registered-users-count");
  return response.data;
};

const getSubscribedUsers = async (): Promise<SubscribedUser[]> => {
  const response = await axiosInstance.get<SubscribedUser[]>("/analytics/subscribed-users");
  return response.data;
};

const getCountOfSubscribedUsers = async (): Promise<RegisteredUserCount> => {
  const response = await axiosInstance.get<RegisteredUserCount>("/analytics/subscribed-users-count");
  return response.data;
};

const getCanceledSubscribedUsers = async (): Promise<SubscribedUser[]> => {
  const response = await axiosInstance.get<SubscribedUser[]>("/analytics/canceled-subscribed-users");
  return response.data;
};

const analyticsServices = {
  getRegisteredUsers,
  getCountOfRegisteredUsers,
  getSubscribedUsers,
  getCountOfSubscribedUsers,
  getCanceledSubscribedUsers
};

export default analyticsServices;
