import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Layout from "../../components/Layout";
import UsersTable from "../../components/UsersTable";
import { useDispatch } from "hooks/useDispatch";
import { fetchRegisteredUsers } from "../../redux/analytics/analyticsActions";

const UsersPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRegisteredUsers());
  }, [dispatch]);
  
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Layout />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
          <UsersTable />
        </Container>
      </Box>
    </Box>
  );
};

export default UsersPage;
