import React, { useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './sidebar/Sidebar';

// Use LayoutProps to type the functional component
const Layout: React.FC= () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const handleDrawerOpen = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };
  return (
    <>
      <Navbar open={isSidebarOpen} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar open={isSidebarOpen} toggleDrawer={handleDrawerClose} />
    </>
  );
};

export default Layout;
