import { Login, CreateUser, AdminUser } from "../../types/apiResponses";
import axiosInstance from "../axiosInstance";

const login = async (username: string, password: string): Promise<Login> => {
  const response = await axiosInstance.post<Login>("/users/login", {
    username,
    password,
  });
  return response.data;
};

const createUser = async (
  username: string,
  password: string
): Promise<CreateUser> => {
  const response = await axiosInstance.post<CreateUser>("/users/admins", {
    username,
    password,
  });
  return response.data;
};

const deleteAdminAccount = async (userId: string): Promise<CreateUser> => {
  const response = await axiosInstance.delete<CreateUser>(
    `/users/admins/${userId}`
  );
  return response.data;
};

const getAdmins = async (): Promise<AdminUser[]> => {
  const response = await axiosInstance.get<AdminUser[]>("/users/admins");
  return response.data;
};

const usersServices = {
  login,
  createUser,
  deleteAdminAccount,
  getAdmins,
};

export default usersServices;
