import { createTheme } from '@mui/material/styles';
declare module '@mui/material/Button' {
  interface PalettePropsColorOverrides {
    custom: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    darkGray?: string;
    dangerText?: string;
    danger?: string;
  }
  interface PaletteOptions {
    darkGray?: string;
    dangerText?: string;
    danger?: string;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#262626', // Black color for primary text
    },
    secondary: {
      main: '#E0F64B', // Yellow color for secondary elements
    },
    info: {
      main: '#58D68D', // Green color for info elements
    },
    success: {
      main: '#6F9C40', // Green color for info elements
      contrastText: '#E9F0E2', // Green color for info elements
    },
    background: {
      default: '#EDF0F7', // Light gray for the page background
      paper: '#FFFFFF', // White for card backgrounds
    },
    text: {
      primary: '#000000', // Black color for primary text
      secondary: 'rgba(0, 0, 0, 0.54)', // Dark gray for secondary text
    },
    darkGray: '#262626',
    divider: 'rgba(0, 0, 0, 0.12)', // Light gray for dividers
    dangerText: '#E04F1A',
    danger: '#FAE5DD',
    warning: {
      main: '#E69F17', // Green color for info elements
      contrastText: '#FFFFFF', // Green color for info elements
    },
  },
  components: {
    // Customize MUI components here if needed
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#333333', // Dark gray for the AppBar (possibly the sidebar)
        },
      },
    },
  },
});

export default theme;
