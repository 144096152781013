import theme from '../theme';
import { blue } from '../theme/palette';

export const handleChipColor = (access: string): string => {
  switch (access) {
    case "no subscription":
      return theme.palette.warning.main;
    case "trialing":
        return blue[400];
    case "active":
      return theme.palette.success.main;
    case "VIP":
        return theme.palette.secondary.main;
    default:
      return theme.palette.grey[500];
  }
};