import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Login, CreateUser, AdminUser } from "../../types/apiResponses";
import {
  login,
  createUser,
  deleteAdminAccount,
  getAdmins,
  logout,
} from "./usersActions";

interface UsersState {
  login: Login;
  createUser: CreateUser | null;
  deleteAdminAccount: CreateUser | null;
  adminUsers: AdminUser[];
  loading: boolean;
  error: string | null;
}

const userDataFromStorage = localStorage.getItem("userInfo");
const parsedUserData = userDataFromStorage
  ? JSON.parse(userDataFromStorage)
  : null;

const initialState: UsersState = {
  login: parsedUserData,
  createUser: null,
  deleteAdminAccount: null,
  adminUsers: [],
  loading: false,
  error: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<UsersState>) => {
    builder
      // Handle pending, fulfilled, and rejected cases for login
      .addCase(login.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(
        login.fulfilled,
        (state: UsersState, action: PayloadAction<Login>) => {
          state.login = action.payload;
          localStorage.setItem("userInfo", JSON.stringify(action.payload));
          state.loading = false;
        }
      )
      .addCase(
        login.rejected,
        (state: UsersState, action: PayloadAction<string | undefined>) => {
          state.error =
            action.payload || "Failed to login. No error message provided.";
          state.loading = false;
        }
      )
      // Handle pending, fulfilled, and rejected cases for logout
      .addCase(logout.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(
        logout.fulfilled,
        (state: UsersState) => {
          // Reset state to initial state when logging out
          state.login = parsedUserData;
          state.createUser = null;
          state.deleteAdminAccount = null;
          state.adminUsers = [];
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        logout.rejected,
        (state: UsersState, action: PayloadAction<string | undefined>) => {
          state.error =
            action.payload || "Failed to logout. No error message provided.";
          state.loading = false;
        }
      )
      // Handle pending, fulfilled, and rejected cases for createUser
      .addCase(createUser.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(
        createUser.fulfilled,
        (state: UsersState, action: PayloadAction<CreateUser>) => {
          state.createUser = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        createUser.rejected,
        (state: UsersState, action: PayloadAction<string | undefined>) => {
          state.error =
            action.payload ||
            "Failed to create user. No error message provided.";
          state.loading = false;
        }
      )
      // Handle pending, fulfilled, and rejected cases for deleteAdminAccount
      .addCase(deleteAdminAccount.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(
        deleteAdminAccount.fulfilled,
        (state: UsersState, action: PayloadAction<CreateUser>) => {
          state.deleteAdminAccount = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        deleteAdminAccount.rejected,
        (state: UsersState, action: PayloadAction<string | undefined>) => {
          state.error =
            action.payload ||
            "Failed to delete user. No error message provided.";
          state.loading = false;
        }
      )
      // Handle pending, fulfilled, and rejected cases for getAdmins
      .addCase(getAdmins.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getAdmins.fulfilled,
        (state, action: PayloadAction<AdminUser[]>) => {
          state.adminUsers = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        getAdmins.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.error =
            action.payload ||
            "Failed to delete user. No error message provided.";
          state.loading = false;
        }
      );
  },
});

export default usersSlice.reducer;
