import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { MainListItems, SecondaryListItems } from "./listItems";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assets";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    backgroundColor: theme.palette.darkGray,
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface SidebarProps {
  open: boolean;
  toggleDrawer: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ open, toggleDrawer }) => {
  const navigate = useNavigate();

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: [1],
        }}
      >
        {open ? (
          <img
            className="w-44 h-10 mb-8 mt-4"
            src={icons.sidebarLogo}
            alt="img"
          />
        ) : (
          <img className="h-10 mb-8 mt-4" src={icons.logo} alt="img" />
        )}
      </Toolbar>
      <Divider />
      <List component="nav">
        <MainListItems navigate={navigate} />
        <Divider sx={{ my: 1 }} />
        <SecondaryListItems navigate={navigate} />
      </List>
    </Drawer>
  );
};

export default Sidebar;
