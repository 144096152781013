
interface Icons {
  logo: string;
  logoDark: string;
  sidebarLogo: string;
}

const icons: Icons = {
  logo: require('./logo.png'),
  logoDark: require('./logo-dark.png'),
  sidebarLogo: require('./sidebar_logo.png'),
}

export default icons