import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./pages/login/SignIn";
import Dashboard from "./pages/dashboard/DashboardPage";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "./theme";
import UsersPage from "./pages/users/UsersPage";
import AdminManagementPage from "./pages/adminManagement/AdminManagementPage";
import { SnackbarProvider } from "contexts/SnackbarContext";
import ProtectedRoute from "middleware/ProtectedRoute";

const App: React.FC = () => {
  return (
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <UsersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/accounts-management"
              element={
                <ProtectedRoute>
                  <AdminManagementPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;
