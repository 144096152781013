import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import analyticsSlice from './analytics/analyticsSlice';
import usersSlice from './users/usersSlice';
import subscriptionSclice from './subscription/subscriptionSclice';

// Configure the store with just the reducers
export const store = configureStore({
  reducer: {
    analytics: analyticsSlice,
    user: usersSlice,
    subscription: subscriptionSclice
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Define the AppThunk type without the extra arguments
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown, // no extra arguments, so 'unknown' is used
  Action<string>
>;