import React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "../theme";
import UserProfile from "./UserProfileComponent";
import { useAppSelector } from "hooks/useDispatch";

interface NavbarProps {
  open: boolean;
  handleDrawerOpen: () => void;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "#ffffff",
  color: theme.palette.text.primary,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${open ? 240 : 72}px)`,
  marginLeft: open ? 240 : 72,
}));

const Navbar: React.FC<NavbarProps> = ({ open, handleDrawerOpen }) => {
  const user = useAppSelector((state) => state.user.login);
  return (
    <AppBar position="absolute" open={open}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between'}}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          sx={{
            marginRight: "36px",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <MenuIcon />
        </IconButton>
        <UserProfile name={user.username} />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
