import { RegisteredUser } from "../types/apiResponses";
import dateUtils from "./dateUtils";
import userUtils from "./userUtils";

const calculateMetrics = (users: RegisteredUser[], startDateString: string, endDateString: string) => {
  const startDate = dateUtils.parseISODate(startDateString);
  const endDate = dateUtils.parseISODate(endDateString);

  const filteredUsers = userUtils.filterUsersByDateRange(users, startDate.toISOString(), endDate.toISOString());
  const newVisitors = filteredUsers.length;

  // Calculate the dates for the previous period
  const previousPeriodStart = new Date(startDate);
  previousPeriodStart.setMonth(startDate.getMonth() - 1);
  const previousPeriodEnd = new Date(endDate);
  previousPeriodEnd.setMonth(endDate.getMonth() - 1);

  const previousPeriodUsers = userUtils.filterUsersByDateRange(
    users,
    previousPeriodStart.toISOString(),
    previousPeriodEnd.toISOString()
  );
  const previousVisitors = previousPeriodUsers.length;
  const growth = previousVisitors ? ((newVisitors - previousVisitors) / previousVisitors) * 100 : 0;

  return {
    newVisitors,
    growth: growth.toFixed(2) // rounds to two decimals
  };
};

const analytics = {
  calculateMetrics
}

export default analytics