import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { changeUserAccess } from "./subscriptionActions";
import { UserAccess } from "types/apiResponses";

interface SubscriptionState {
  userAccessState: UserAccess | null;
  loading: boolean;
  error: string | null;
}

const initialState: SubscriptionState = {
  userAccessState: null,
  loading: false,
  error: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<SubscriptionState>) => {
    builder
      .addCase(changeUserAccess.pending, (state: SubscriptionState) => {
        state.loading = true;
      })
      .addCase(changeUserAccess.fulfilled, (state: SubscriptionState, action: PayloadAction<UserAccess>) => {
        state.userAccessState = action.payload;
        state.loading = false;
      })
      .addCase(changeUserAccess.rejected, (state: SubscriptionState, action: PayloadAction<string | undefined>) => {
        state.error = action.payload || 'Failed to change user access. No error message provided.';
        state.loading = false;
      })
  },
});


export default subscriptionSlice.reducer;
