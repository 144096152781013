import * as React from 'react';
import { Button, Menu, MenuItem, styled } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch } from '../hooks/useDispatch';
import { logout } from '../redux/users/usersActions';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)({
  backgroundColor: '#E0E0E0',
  color: '#000000',
  textTransform: 'none', 
  fontWeight: 'bold',
  borderRadius: '10px',
  height: '40px',
  paddingLeft: '15px',
  paddingRight: '15px',
  '&:hover': {
    backgroundColor: '#D5D5D5',
  },
  boxShadow: 'none',
});

interface UserProfileProps {
  name: string;
}

const AdminButton: React.FC<UserProfileProps> = ({name}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }

  return (
    <div>
      <StyledButton 
        endIcon={<ArrowDropDownIcon />} 
        onClick={handleClick}
      >
        {name}
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* Add MenuItems here */}
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>Settings</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default AdminButton;
