import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import usersServices from '../../api/services/usersService';
import { Login, CreateUser, ErrorResponse, AdminUser } from '../../types/apiResponses';

export const login = createAsyncThunk<Login, { username: string, password: string }, { rejectValue: string }>(
  'users/login',
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const response = await usersServices.login(username, password);
      localStorage.setItem('userInfo', JSON.stringify(response));
      return response
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      return rejectWithValue(axiosError.response?.data.message || 'An unknown error occurred');
    }
  }
);

export const logout = createAsyncThunk<void, void, {rejectValue: string}>(
  'users/logout',
  async(_, {rejectWithValue}) => {
    try {
      localStorage.removeItem('userInfo');
      return;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      return rejectWithValue(axiosError.response?.data.message || 'An unknown error occurred')
    }
  }
)

export const createUser = createAsyncThunk<CreateUser, { username: string, password: string }, { rejectValue: string }>(
  'users/create-admin',
  async ({ username, password }, thunkAPI) => {
    try {
      const response = await usersServices.createUser(username, password);
      await thunkAPI.dispatch(getAdmins());
      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      return thunkAPI.rejectWithValue(axiosError.response?.data.message || 'An unknown error occurred');
    }
  }
);

export const deleteAdminAccount = createAsyncThunk<CreateUser, { userId: string }, { rejectValue: string }>(
  'users/delete-admin',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await usersServices.deleteAdminAccount(userId);
      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      return rejectWithValue(axiosError.response?.data.message || 'An unknown error occurred');
    }
  }
);

export const getAdmins = createAsyncThunk<AdminUser[], void, { rejectValue: string }>(
  'users/get-admins',
  async (_, { rejectWithValue }) => {
    try {
      const response = await usersServices.getAdmins();
      return response;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      return rejectWithValue(axiosError.response?.data.message || 'An unknown error occurred');
    }
  }
);
