import { createAsyncThunk } from "@reduxjs/toolkit";
import subscriptionServices from "../../api/services/subscriptionService";
import { AxiosError } from "axios";
import { fetchRegisteredUsers } from "../../redux/analytics/analyticsActions";
import { ErrorResponse, UserAccess } from "../../types/apiResponses";

export const changeUserAccess = createAsyncThunk<
  UserAccess,
  { userId: string, grantAccess: boolean },
  { 
    rejectValue: string
  }
>(
  'users/changeUserAccess',
  async ({ userId, grantAccess }, thunkAPI) => {
    try {
      const response = await subscriptionServices.changeUserAccess(userId, grantAccess);
      await thunkAPI.dispatch(fetchRegisteredUsers());
      return { message: response.message };
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      return thunkAPI.rejectWithValue(axiosError.response?.data.message || 'An unknown error occurred');
    }
  }
);