import { createAsyncThunk } from '@reduxjs/toolkit';
import analyticsServices from '../../api/services/analyticsService';
import { RegisteredUser, RegisteredUserCount, SubscribedUser } from '../../types/apiResponses';

export const fetchRegisteredUsers = createAsyncThunk<RegisteredUser[]>(
  'analytics/registered-users',
  async () => {
    const response = await analyticsServices.getRegisteredUsers();
    return response;
  }
);

export const fetchRegisteredUsersCount = createAsyncThunk<RegisteredUserCount>(
  'analytics/registered-users-count',
  async () => {
    const response = await analyticsServices.getCountOfRegisteredUsers();
    return response;
  }
);

export const fetchSubscribedUsers = createAsyncThunk<SubscribedUser[]>(
  'analytics/subscribed-users',
  async () => {
    const response = await analyticsServices.getSubscribedUsers();
    return response;
  }
);

export const fetchSubscribedUsersCount = createAsyncThunk<RegisteredUserCount>(
  'analytics/subscribed-users-count',
  async () => {
    const response = await analyticsServices.getCountOfSubscribedUsers();
    return response;
  }
);

export const fetchCanceledSubscriptions = createAsyncThunk<SubscribedUser[]>(
  'analytics/canceled-subscribed-users',
  async () => {
    const response = await analyticsServices.getCanceledSubscribedUsers();
    return response;
  }
);