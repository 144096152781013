import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { RegisteredUser, RegisteredUserCount, SubscribedUser } from "../../types/apiResponses";
import { fetchCanceledSubscriptions, fetchRegisteredUsers, fetchRegisteredUsersCount, fetchSubscribedUsers, fetchSubscribedUsersCount } from "./analyticsActions";

interface AnalyticsState {
  registeredUsers: RegisteredUser[];
  subscribedUsers: SubscribedUser[];
  canceledUsers: SubscribedUser[];
  registeredUsersCount: RegisteredUserCount | null;
  subscribedUsersCount: RegisteredUserCount | null;
  loading: boolean;
  error: string | null;
}

const initialState: AnalyticsState = {
  registeredUsers: [],
  subscribedUsers: [],
  canceledUsers: [],
  registeredUsersCount: null,
  subscribedUsersCount: null,
  loading: false,
  error: null,
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AnalyticsState>) => {
    builder
      // Handle pending, fulfilled, and rejected cases for fetchRegisteredUsers
      .addCase(fetchRegisteredUsers.pending, (state: AnalyticsState) => {
        state.loading = true;
      })
      .addCase(fetchRegisteredUsers.fulfilled, (state: AnalyticsState, action) => {
        state.registeredUsers = action.payload;
        state.loading = false;
      })
      .addCase(fetchRegisteredUsers.rejected, (state: AnalyticsState, action) => {
        state.error = action.error.message || 'Could not fetch registered users.';
        state.loading = false;
      })
      // Handle pending, fulfilled, and rejected cases for fetchRegisteredUsersCount
      .addCase(fetchRegisteredUsersCount.pending, (state: AnalyticsState) => {
        state.loading = true;
      })
      .addCase(fetchRegisteredUsersCount.fulfilled, (state: AnalyticsState, action) => {
        state.registeredUsersCount = action.payload;
        state.loading = false;
      })
      .addCase(fetchRegisteredUsersCount.rejected, (state: AnalyticsState, action) => {
        state.error = action.error.message || 'Could not fetch registered users count.';
        state.loading = false;
      })
      // Handle pending, fulfilled, and rejected cases for fetchSubscribedUsers
      .addCase(fetchSubscribedUsers.pending, (state: AnalyticsState) => {
        state.loading = true;
      })
      .addCase(fetchSubscribedUsers.fulfilled, (state: AnalyticsState, action) => {
        state.subscribedUsers = action.payload;
        state.loading = false;
      })
      .addCase(fetchSubscribedUsers.rejected, (state: AnalyticsState, action) => {
        state.error = action.error.message || 'Could not fetch subscribed users.';
        state.loading = false;
      })
      // Handle pending, fulfilled, and rejected cases for fetchSubscribedUsersCount
      .addCase(fetchSubscribedUsersCount.pending, (state: AnalyticsState) => {
        state.loading = true;
      })
      .addCase(fetchSubscribedUsersCount.fulfilled, (state: AnalyticsState, action) => {
        state.subscribedUsersCount = action.payload;
        state.loading = false;
      })
      .addCase(fetchSubscribedUsersCount.rejected, (state: AnalyticsState, action) => {
        state.error = action.error.message || 'Could not fetch registered users count.';
        state.loading = false;
      })
      // Handle pending, fulfilled, and rejected cases for fetchCanceledSubscriptions
      .addCase(fetchCanceledSubscriptions.pending, (state: AnalyticsState) => {
        state.loading = true;
      })
      .addCase(fetchCanceledSubscriptions.fulfilled, (state: AnalyticsState, action) => {
        state.canceledUsers = action.payload;
        state.loading = false;
      })
      .addCase(fetchCanceledSubscriptions.rejected, (state: AnalyticsState, action) => {
        state.error = action.error.message || 'Could not fetch registered users count.';
        state.loading = false;
      })
  },
});

export default analyticsSlice.reducer;