import * as React from "react";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import userUtils, { periods } from "../utils/userUtils";
import dateUtils from "../utils/dateUtils";
import analytics from "../utils/analytics";
import { PeriodDropdown } from "./PeriodDropdown";
import { useAppSelector } from "../hooks/useDispatch";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function UserGrowthBarChart() {
  const [selectedPeriod, setSelectedPeriod] = React.useState<string>(
    periods.allTime
  );
  const [chartData, setChartData] = React.useState<
    { argument: string; value: number }[]
  >([]);
  const [metrics, setMetrics] = React.useState({
    newVisitors: 0,
    growth: "0.00",
  });

  const userData = useAppSelector((state) => state.analytics.registeredUsers);

  const theme = useTheme();

  // Effect hook to update chart data when selectedPeriod changes
  React.useEffect(() => {
    const updatedChartData = userUtils.transformUserData(
      userData,
      selectedPeriod
    );
    const [startDate, endDate] = dateUtils.getStartEndDateForThisWeek();
    const newMetrics = analytics.calculateMetrics(userData, startDate, endDate);
    setMetrics(newMetrics);
    setChartData(updatedChartData);
  }, [selectedPeriod, userData]);

  const chartDataset = {
    labels: chartData.map((dataPoint) => dataPoint.argument), // This will create an array of labels from the 'argument' field
    datasets: [
      {
        label: "User Growth",
        data: chartData.map((dataPoint) => dataPoint.value), // This will create an array of data points from the 'value' field
        backgroundColor: "#E0F64B",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "User Growth Chart",
      },
    },
  };

  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);
    // You would also handle updating any other state or fetching data here if needed
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Users Growth
        </Typography>

        <PeriodDropdown
          selectedPeriod={selectedPeriod}
          onSelectPeriod={handlePeriodChange}
        />
      </Box>
      <Card sx={{ height: "100%", boxShadow: theme.shadows[3], borderRadius: 2 }}>
        <Box
          sx={{
            display: "flex",
            //justifyContent: "space-between",
            alignItems: "center",
            padding: 3,
          }}
        >
            <Bar data={chartDataset} options={chartOptions} />
        </Box>
      </Card>
    </>
  );
}
