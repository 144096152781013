import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Cards from "../../components/overview/Cards";
import UserGrowthBarChart from "../../components/UserBarChart";
import Layout from "../../components/Layout";
import QuickStatistics from "../../components/quickStatistics/quickStatistics";
import { useDispatch } from "../../hooks/useDispatch";
import { fetchCanceledSubscriptions, fetchRegisteredUsers, fetchRegisteredUsersCount, fetchSubscribedUsers } from "../../redux/analytics/analyticsActions";

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRegisteredUsersCount());
    dispatch(fetchSubscribedUsers());
    dispatch(fetchCanceledSubscriptions());
    dispatch(fetchRegisteredUsers());
  }, [dispatch]);
  
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Layout />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
          <Cards />
          <Box sx={{ flexGrow: 1, marginTop: 5 }}>
          <UserGrowthBarChart />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Dashboard;
