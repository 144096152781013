import { UserAccess } from "../../types/apiResponses";
import axiosInstance from "../axiosInstance";

const changeUserAccess = async (
  userId: string,
  grantAccess: boolean,
): Promise<UserAccess> => {
  const response = await axiosInstance.post<UserAccess>("/subscription/change-user-access", {
    userId,
    grantAccess
  });
  return response.data;
};

const subscriptionServices = {
  changeUserAccess,
};

export default subscriptionServices;
