import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, FormControl, IconButton } from "@mui/material";
import theme from "../theme";
import { BootstrapInput } from "../theme/components";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useAppSelector } from "hooks/useDispatch";
import AddAdminUserModal from "./modals/AddAdminUserModal";

export default function AdminUsersTable() {
  const [open, setOpen] = React.useState(false);

  const users = useAppSelector((state) => state.user.adminUsers);

  const handleOpenCreateAdminUserModal = () => {
    setOpen(true);
  }
  return (
    <Box component={Paper}>
      <Box sx={{ m: 1, display: "flex", justifyContent: "space-between" }}>
        <Button sx={{ m: 1 }} onClick={handleOpenCreateAdminUserModal} variant="contained" endIcon={<AddIcon />}>
          Create User
        </Button>
        <FormControl sx={{ m: 1 }} variant="standard">
          <BootstrapInput
            id="demo-customized-textbox"
            placeholder="Search..."
          />
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Access</TableCell>
              <TableCell align="center">Registered</TableCell>
              <TableCell align="center">Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  {user.username}
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                </TableCell>
                <TableCell align="center">{new Date(user.createdAt).toLocaleDateString()}</TableCell>
                <TableCell align="center">
                  <IconButton
                    //edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    //onClick={() => handleEditUserModal(user)}
                    sx={{
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>

              </TableRow>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddAdminUserModal open={open} handleClose={() => setOpen(false)} />
    </Box>
  );
}
