import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import theme from "../../theme";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { handleChipColor } from "../../utils/chipColorHelpers";
import { access } from "../../utils/userUtils";
import { RegisteredUser } from "types/apiResponses";
import { useAppSelector, useDispatch } from "hooks/useDispatch";
import { changeUserAccess } from "../../redux/subscription/subscriptionActions";
import { useSnackbar } from "contexts/SnackbarContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: theme.shadows[3],
  borderRadius: 2,
  //p: 4,
};

interface EditUserModalProps {
  user: RegisteredUser;
  open: boolean;
  handleClose: () => void;
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  user,
  open,
  handleClose,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [userAccess, setUserAccess] = React.useState<string>(
    user.subscription ? user.subscription.subscriptionStatus : "no subscription"
  );
  const isMenuOpen = Boolean(anchorEl);

  const {error, userAccessState} = useAppSelector(state => state.subscription)
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();

  const accessArray = Object.values(access);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (accessArray.includes(userAccess)) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeUserAccess = (plan: string) => {
    setUserAccess(plan);
    handleMenuClose()
  };

  const handleSave = () => {
    if (userAccess === (user.subscription ? user.subscription.subscriptionStatus : "no subscription")) {
      handleClose()
    } else {
      setOpenDialog(true)
    }
  }

  React.useEffect(() => {
    if (userAccessState) {
      showMessage(userAccessState.message, 'success');
    }
  }, [userAccessState]);
  
  React.useEffect(() => {
    if (error) {
      showMessage(error, 'error');
    }
  }, [error]);

  const handleAgree = async () => {
    const grantAccess = userAccess === "VIP";
    setOpenDialog(false); // Close the dialog as you're starting the process.
  
    try {
      // Dispatch the action and wait for it to finish.
      await dispatch(changeUserAccess({ userId: user._id, grantAccess })).unwrap();
  
      // Use a callback or useEffect to respond to state changes.
      if (userAccessState) {
        showMessage(userAccessState.message, 'success');
      }
    } catch (err) {
      if (err instanceof Error) {
        showMessage(err.message, 'error');
      } else {
        // If it's not an Error instance, you might want to stringify it or show a default message.
        showMessage("An unknown error occurred", 'error');
      }
    }
  
    handleMenuClose();
    handleClose();
  };
  

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              boxShadow: theme.shadows[3],
              padding: 1,
            }}
          >
            <Typography id="edit-user-modal-title" variant="h6">
              Edit User
            </Typography>
            <IconButton onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Box>
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Name:
                </Typography>
                <Typography>{user.name}</Typography>
              </Box>
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Email:
                </Typography>
                <Typography>{user.email}</Typography>
              </Box>
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Access:
                </Typography>
                <Button onClick={handleClick}>
                  <Chip
                    label={userAccess}
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: handleChipColor(userAccess),
                      color: "white",
                      fontSize: "0.875rem",
                    }}
                  />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {accessArray.map((plan: string, index: number) => (
                    <MenuItem
                      onClick={() => handleChangeUserAccess(plan)}
                      key={index}
                    >
                      <Chip
                        label={plan}
                        sx={{
                          fontWeight: "bold",
                          backgroundColor: handleChipColor(plan),
                          color: "white",
                          fontSize: "0.875rem",
                        }}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Registered:
                </Typography>
                <Typography>
                  {new Date(user.createdAt).toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              boxShadow: theme.shadows[3],
              padding: 1,
            }}
          >
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={handleSave} variant="outlined">Save</Button>

            <Dialog
              open={openDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Save Changes?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Do you want to apply the changes made?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleMenuClose}>Disagree</Button>
                <Button onClick={handleAgree} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EditUserModal;
